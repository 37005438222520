<template>
    <div class="layout">
        <slot></slot>
    </div>
</template>

<script>
  export default {
    name: 'gridV2'
  }
</script>

<style scoped lang="scss">
    .layout {
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
    }
</style>